/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: Mainframe Regular;
  src: url(./assets/fonts/PragmataMedium.ttf);
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: black;
  color: #64bcb6;
  font-family: 'Mainframe Regular', Courier, monospace;
  font-size: 1vw; //1.2vw before
  overflow: hidden;
  height: 100%;
  width: 100%;
}

hr {
  border-top: #4C5B98 solid 1px;
  margin: 0;
}

.choices {
  margin-left: 5vw;
  margin-bottom: 0;
  cursor: pointer;
  list-style-type: none;

  &-yn {
    @extend.choices;
    margin-left: 4vw;
  }

  &-main {
    @extend.choices;
    margin-left: 4vw;
    margin-bottom: 1vw;

    &-disable {
      @extend.choices-main;
      color: #545454;
      border: none;
    }

  }

  &-cont {
    margin-left: 9vw;
  }
}

.choices:hover,
.choices-yn:hover {
  color: #397475;
  border: none;
}

.choices-main-disable:hover {
  color: #545454;
  border: none;
  cursor: default;
}

.page-title {
  font-size: 1.35vw;

  &sub {
    @extend.page-title;
    float: right;
  }
}

.mf-holder {
  margin: 4% 25%;
}



.menu-above {
  color: #FFFFFF;
  margin: 10px;
}

.menu-holder {
  margin-bottom: 10px;
}

.title-holder {
  padding-top: 10px;
}

.title1,
.CSR {
  color: #57EDED;
}

.mf-input {
  resize: none;
  margin: 0px;
  border: none;
  outline: none;
  background-color: #000000;
  color: #397475;
  overflow: hidden;
  font-size: 1.2vw;
}

.mf-input-edit {
  resize: none;
  margin: 0px;
  border-bottom: #FFFFFF solid 1px;
  color: #ffff00;
  font-size: 1vw;
  background-color: #000000;
  overflow: hidden;

  &-1 {
    @extend.mf-input-edit;
    width: 12vw;
  }

  &-2 {
    @extend.mf-input-edit;
    width: 11vw;
  }

  &-3 {
    @extend.mf-input-edit;
    width: 8vw;
  }
}

.columns {
  padding-right: 6px;
}

.line {
  color: rgb(19, 20, 22);
}

.main-title,
.submain-title {
  text-align: center;
}

.menu-num {
  text-align: right;
  margin-right: 2.5vw;
}

.form {
  padding-left: 15px;
  ;
}

.btn {
  margin: 1vw 1vw 0 1vw;
  padding: 0.3vw;
  width: 12vw;
  color: #FFFFFF;
  border-radius: 0.5vw;

  &-download {
    @extend .btn;
    background-color: #4C5B98;
    border: 0.05vw solid #FFFFFF;
  }

  &-exit {
    @extend .btn;
    background-color: #000000;
    border: 0.05vw solid #4C5B98;
  }

  &-submit {
    @extend .btn;
    background-color: #4C5B98;
    border: 0.05vw solid #FFFFFF;
    width: 10vw;
    margin: 0.75vw 0.25vw 0 0.25vw;
    font-size: 1vw;
  }

  &-save {
    @extend .btn;
    background-color: #4C5B98;
    border: 0.05vw solid #FFFFFF;
    width: 10vw;
    margin: 0.75vw 0.25vw 0 0.25vw;
    font-size: 1vw;
  }

  &-disable {
    @extend .btn;
    background-color: #545454;
    border: 0.05vw solid #FFFFFF;
    color: #AFBACC;
    width: 10vw;
    margin: 0.75vw 0.25vw 0 0.25vw;
    font-size: 1vw;
  }

  &-cancel {
    @extend .btn;
    background-color: pink;
    border: 0.12vw solid #4C5B98;
    width: 10vw;
    margin: 0.75vw 0 0 0.25vw;
    font-size: 1vw;
  }

  &-holder {
    text-align: center;
  }

  &-search {
    @extend .btn;
    background-color: #4C5B98;
    border: 0.05vw solid #FFFFFF;
    width: 4vw;
    margin: 0.75vw 0.2vw 0.6vw 0.2vw;
    font-size: 0.8vw;
  }

  &-clear {
    @extend .btn;
    background-color: #000000;
    border: 0.05vw solid #4C5B98;
    width: 4vw;
    margin: 0.75vw 0.25vw 0.6vw 0.25vw;
    font-size: 0.8vw;
  }
}

.icon {
  width: 1.7vw;
  border-radius: 5px;
  margin-right: 0.5vw;
  padding: 2px;

  &-search {
    background-color: #4C5B98;
    border: solid 1px #FFFFFF;
  }

  &-reset {
    background-color: #000000;
    border: solid 1px #4C5B98;
  }
}

/* by functionality */
.no-padd {
  padding: 0%;
  margin: 0%;
}

.padd-right {
  padding-right: 10px;
}

.Right {
  float: right;
}

.red {
  color: red;
}

ul {
  list-style-type: none;
}

.green {
  color: #26E232;
}

.yellow {
  color: #FFFF00;
}

.white {
  color: #FFFFFF;
}

.default {
  cursor: default;
}

[hidden] {
  display: none !important;
}
